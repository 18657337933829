import StrapiImage from '../../types/StrapiImage'
import Image from '../Image'
import PageWidth from '../PageWidth'

interface PartnersProps {
  title: string
  partner: {
    url: string
    image: StrapiImage
    id: number
  }[]
}

const Partners = ({ partner, title }: PartnersProps) => (
  <section className="blockPartners">
    <p className="blockPartners__title">{title}</p>
    <PageWidth>
      <div className="blockPartners__box">
        {partner.map((p) => (
          <a
            key={p.id}
            href={p.url}
            target="_blank"
            rel="noreferrer"
            className="blockPartners__partner"
          >
            <Image
              image={p.image}
              className="blockPartners__image"
              format="small"
            ></Image>
          </a>
        ))}
      </div>
    </PageWidth>
  </section>
)

export default Partners
